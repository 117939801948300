.introduction-template {
  position: relative;
  scroll-behavior: smooth;
  //overflow: hidden;

  .fixed-templates {
    position: relative;
    margin-top: 720px;
    background: #ffffff;
    scroll-behavior: smooth;
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .fixed-templates {
      margin-top: 420px;
    }
  }
}
