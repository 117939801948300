.introduction-template {
  .interview-section {
    height: auto;
    width: 100%;

    .about-section {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 540px;
      background-size: contain;
      -webkit-background-size: contain;
      text-align: center;
      color: #333;

      &__title {
        font-family: Volte, sans-serif;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: #1b7ded;
      }
      &__subtitle {
        margin-top: 50px;
        font-family: NotoSansCJKkr, sans-serif;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: -0.32px;
      }
      &__contents {
        width: 640px;
        margin-top: 50px;
        font-family: NotoSansCJKkr, sans-serif;
        font-size: 18px;
        letter-spacing: -0.2px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .interview-section {
      .about-section {
        height: 420px;

        &__title {
          font-size: 18px;
        }
        &__subtitle {
          margin-top: 30px;
          padding: 0 30px;
          font-size: 18px;
          letter-spacing: -0.18px;
        }
        &__contents {
          //width: 300px;
          width: auto;
          margin-top: 20px;
          padding: 0 30px;
          font-size: 16px;
          letter-spacing: -0.18px;
        }
      }
    }
  }
}
