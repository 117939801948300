.o2solution-template {
  .step-summary-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f4f7fa;

    gap: 104px;
    list-style: none;
    margin: 0;
    padding: 140px 0;

    .step-summary-section__item {
      width: 288px;
      position: relative;
    }

    .step-summary-section__step {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      height: 40px;
      color: #1b7dec;
      font-weight: 700;
      padding: 8px 16px;
      border: 3px solid #1b7dec;
      border-radius: 30px;
    }
    .step-summary-section__description {
      box-sizing: border-box;
      min-height: 121px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 16px;
      padding: 0;
      background-color: #fff;
    }
    .step-summary-section__title {
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      color: #333;
      padding: 16px 0;
    }
    .step-summary-section__description-title {
      color: #1b7dec;
      font-weight: 700;
      padding-bottom: 8px;
    }
    .step-summary-section__arrow {
      display: none;
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .step-summary-section {
      flex-direction: row;
      align-items: flex-start;

      .step-summary-section__arrow {
        display: block;
        width: 26px;
        height: 23px;
        position: absolute;
        top: 50%;
        left: -62px;
        margin-top: -14px;
      }
    }
  }
}
