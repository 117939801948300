.o2solution-template {
  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, Spoqa, 'NanumSquare', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  text-align: left;
  font-size: 20px;
  line-height: 1.4;
  color: #555b62;

  .br {
    content: '';
    &::after {
      content: '\00a0';
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .br {
      display: block;
      height: 0;
    }
  }
}
