.header-section {
  background: none;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  height: auto;
  padding: 20px 35px;
  transition: background 0.3s ease-in 0s;
  -o-transition: background 0.3s ease-in 0s;
  -moz-transition: background 0.3s ease-in 0s;
  -webkit-transition: background 0.3s ease-in 0s;

  //border-bottom: 1px solid #f2f2f2;

  .header__logo {
    img {
      width: 135px;
      height: auto;
    }
  }

  .header__menu-container {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;

    .header__menu {
      margin-left: 24px;
      font-size: 18px;
      font-family: Volte, sans-serif;
      font-weight: 500;
      color: #f2f2f2;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
.above-content {
  background: #ffffff;
  border-bottom: 1px solid #ddd;

  .header__menu-container {
    .header__menu {
      color: #000000;
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-section {
    background: #ffffff;
    height: auto;
    padding: 22px 16px;

    .header__logo {
      img {
        width: 80px;
        height: auto;
      }
    }
    .header__menu-container {
      .header__menu {
        font-size: 12px;
        font-family: NanumSquare, sans-serif;
        color: #000000;
      }
    }
  }
}
