.privacy-template {
  .privacy-section {
    margin: 30px 200px;
    text-align: left;
    line-height: 1.6;
  }
  .footer-section {
    justify-content: flex-start;

    .footer {
      margin-left: 200px;
    }
  }
}
@media only screen and (max-width: 1023px) {
  .privacy-template {
    .privacy-section {
      margin: 30px 100px;
    }
    .footer-section {
      justify-content: flex-start;

      .footer {
        margin-left: 50px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .privacy-template {
    .privacy-section {
      margin: 30px 50px;
    }
    .footer-section {
      justify-content: flex-start;

      .footer {
        margin-left: 20px;
      }
    }
  }
}

