.introduction-template {
  .top-section {
    background: linear-gradient(to bottom, rgba(0, 7, 17, 0.5), rgba(0, 7, 17, 0.2), rgba(0, 20, 46, 0)), url('../../../static/images/introduction/zipfund-mockup_desktop.png') no-repeat center center;
    background-size: cover;
    //-webkit-background-size: cover;
    position: fixed;
    top: 0;
    z-index: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    //min-width: 1280px;
    height: 720px;
    font-family: NotoSansCJKkr, sans-serif;
    color: #fff;

    .introduction__container {
      text-align: left;
      position: relative;
      left: -5%;
      top: 3%;
    }
    .introduction__title {
      font-size: 40px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: -0.3px;
      color: #fff;
      text-transform: uppercase;
      text-align: left;
      line-height: 1.3;
    }
    .video__button {
      background: transparent;
      text-align: center;
      width: 164px;
      height: 48px;
      border: solid 1px #fff;
      margin-top: 38px;
      line-height: 48px;
      font-size: 16px;
      font-weight: 600;
      font-family: NotoSansCJKkr, sans-serif;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .top-section {
      //background: url("../../../static/images/introduction/zipfund-mockup_mobile@2x.png") no-repeat center center;
      background-size: cover;
      height: 420px;
      .introduction__container {
        left: -2%;
        top: 28px;
      }
      .introduction__title {
        width: 300px;
        font-size: 24px;
        letter-spacing: 0;
      }
      .video__button {
        width: 128px;
        height: 42px;
        margin-top: 20px;
        font-size: 12px;
        line-height: 42px;
      }
    }
  }
}
