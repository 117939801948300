.introduction-template {
  .story-section {
    padding: 130px 0;
    display: flex;
    flex-flow: column;
    align-items: center;

    &__title {
      font-family: Volte, sans-serif;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 800;
      color: #1b7ded;
    }
    &__stories {
      display: flex;
      flex-flow: column nowrap;
      text-align: left;
      margin-top: 70px;
      width: 720px;

      .story {
        margin-top: 30px;
        display: flex;
        flex-flow: row nowrap;
        &:first-child {
          margin-top: 0;
        }
        &__date {
          width: 75px;
          font-family: Volte, sans-serif;
          font-size: 18px;
          font-weight: 600;
          color: #73c3ff;
        }
        &__contents {
          margin-left: 20px;
          font-family: NotoSansCJKkr, sans-serif;
          font-size: 18px;
          letter-spacing: -0.3px;
          font-weight: bold;
          color: #333;
        }
      }
      .more {
        height: 80px;
        line-height: 80px;
        margin-top: 50px;
        border-top: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
        font-size: 18px;
        text-align: center;
        color: #333333;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .story-section {
      padding-top: 110px;
      padding-bottom: 100px;

      &__stories {
        margin-top: 40px;
        text-align: center;
        width: 100%;

        .story {
          flex-flow: column nowrap;
          margin-top: 30px;

          &__date {
            width: 100%;
            font-size: 15px;
          }
          &__contents {
            font-size: 15px;
            margin-top: 6px;
            margin-left: 0;
            padding: 0 20px;
          }
        }
        .more {
          height: 54px;
          line-height: 54px;
          margin-top: 50px;
          margin-right: 20px;
          margin-left: 20px;
          font-size: 16px;
          font-weight: 500;
          border: solid 1px #e8e8e8;
        }
      }
    }
  }
}
