.o2solution-template {
  .what-they-say-section {
    background-color: #f4f7fa;
    text-align: center;
    padding: 140px 20px;

    & > h3 {
      margin: 0;
      padding-bottom: 48px;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      color: #1b7dec;
    }

    .what-they-say-section__items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 54px;
    }
    .what-they-say-section__item {
      max-width: 400px;
      min-height: 541px;
      background-color: #fff;
      border-radius: 24px;
      padding: 32px 32px 0 32px;

      .what-they-say-section__item_title {
        margin: 24px 0;
        color: #333;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
      }

      .what-they-say-section__item_text {
        text-align: left;
        font-size: 20px;
        word-break: keep-all;

        & > p {
          margin: 16px 0;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .what-they-say-section {
      .what-they-say-section__items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 54px;
      }
    }
  }
}
