.o2solution-template {
  .why-section {
    padding: 86px 0 76px 0;

    .why-section__text {
      padding: 0 20px;
      font-size: 20px;

      & > p {
        margin: 8px 0;
      }

      & > h3 {
        margin: 0 0 32px 0;
        color: #333;
        font-size: 32px;
        font-weight: 700;
        line-height: 1;

        & > span {
          color: #1b7dec;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .why-section {
      .why-section__text {
        font-size: 24px;

        & > h3 {
          font-size: 40px;
          font-weight: 700;
          line-height: 1;
        }
      }
    }
  }
}
