.introduction-template {
  .banner-section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;

    & > div {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 160px;
      width: 85%;
      max-width: 940px;
      min-width: 735px;
    }

    &__story {
      border-radius: 4px;
      border: solid 1px rgba(192, 228, 255, 0.5);
      background-color: #f4faff;

      button {
        background-color: #1b7ded;
      }
      img {
        width: 277px;
        height: 182px;
        margin-top: -22px;
        margin-right: 40px;
      }
    }
    &__post {
      margin-top: 40px;
      border-radius: 4px;
      border: solid 1px #86e5ab;
      background-color: #f9fffb;

      button {
        background-color: #19ce60;
      }
      img {
        width: 254px;
        height: 140px;
        margin-top: 20px;
        margin-right: 80px;
      }
    }
    .banner-info {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      padding-top: 38px;
      padding-bottom: 30px;
      padding-left: 50px;
      text-align: left;

      &__text {
        font-size: 20px;
        font-weight: bold;
        color: #2f2e41;
      }
      &__link {
        cursor: pointer;
        button {
          width: 136px;
          height: 44px;
          border-radius: 2px;
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .banner-section {
      margin-left: 20px;
      margin-right: 20px;

      & > div {
        position: relative;
        display: block;
        width: 100%;
        height: 268px;
        max-width: none;
        min-width: 320px;
        overflow: hidden;
      }

      &__story {
        border-radius: 4px;
        border: solid 1px rgba(192, 228, 255, 0.5);
        background-color: #f4faff;

        button {
          background-color: #1b7ded;
        }
        img {
          width: 277px;
          height: 182px;
          margin-top: 0;
          margin-right: 0;
          transform: scale(0.85);
        }
      }
      &__post {
        border-radius: 4px;
        border: solid 1px #86e5ab;
        background-color: #f9fffb;
        text-align: center;

        button {
          background-color: #19ce60;
        }
        img {
          width: 254px;
          height: 140px;
          margin-top: 20px;
          margin-right: 0;
          // transform: scale(0.8);
        }
      }
      .banner-info {
        display: block;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        text-align: center;

        &__text {
          padding-top: 24px;
          margin-bottom: 16px;
          font-size: 22px;
          font-weight: bold;
          line-height: 30px;
          min-width: 280px;
          word-break: keep-all;
        }
        &__link {
          cursor: pointer;
          button {
            width: 136px;
            height: 44px;
            border-radius: 2px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
          }
        }
        .delimiter {
          display: block;
        }
      }
    }
  }
}
