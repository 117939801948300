.o2solution-template {
  .step-section {
    padding: 0 20px;
    .step-section__step {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      height: 40px;
      color: #1b7dec;
      font-weight: 700;
      padding: 8px 16px;
      border: 3px solid #1b7dec;
      border-radius: 30px;
    }
    .step-section__title {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      margin: 16px 0 32px 0;
      color: #333;
    }
    .step-section__description {
      font-size: 20px;
      word-break: keep-all;

      & > p {
        margin: 8px 0;
      }
    }
    .step-section__tag {
      margin-top: 32px;
      font-size: 20px;
      line-height: 29px;
      font-weight: 500;
      color: #1b7dec;
    }
    .step-section__content--1 {
      padding: 60px 0;

      .step-section__text-content {
        padding-top: 35px;
      }
    }
    .step-section__visual-1 {
      text-align: center;

      .step-section__visual-1-arrow {
        display: block;
        margin: -50px auto 8px auto;
      }
      .step-section__visual-1-text {
        color: #1b7dec;
        font-size: 16px;
        font-weight: 500;
        border: 3px solid #1b7dec;
        border-radius: 12px;
        padding: 16px 12px;
        margin: 0 32px 0 28px;
      }
    }

    .step-section__content--2 {
      padding: 0;

      .step-section__text-content {
        padding-top: 71px;
      }
    }

    .step-section__content--3 {
      padding: 100px 0 60px 0;

      .step-section__text-content {
        padding-top: 0;
      }
      .step-section__visual-content {
        text-align: center;
      }
    }
    .step-section-info-box {
      box-sizing: border-box;
      border: 2px solid transparent;
      padding: 16px 24px;
      border-radius: 12px;
      color: #555b62;
      font-size: 16px;
      text-align: left;
      margin-left: 0;

      .step-section-info-box__etc {
        color: #1b7dec;
      }

      & > strong {
        font-weight: 700;
      }
      & > ul {
        margin: 8px 0 0 16px;
        padding: 0;
        list-style: none;

        & > li::before {
          display: inline-block;
          width: 8px;
          text-align: center;
          content: '•';
          margin-left: -16px;
          margin-right: 6px;
        }
      }

      &.step-section-info-box__highlight {
        background-color: #e8f0fe;
        border-color: #1b7dec;
        margin-left: 0;

        & > strong {
          color: #1b7dec;
        }
        & > ul {
          display: grid;
        }
      }
    }
    .step-section-point-line {
      position: relative;
      width: 11px;
      margin: 0 auto -2px auto;
      .step-section-point-line__line {
        margin: 0 auto;
        width: 0;
        height: 40px;
        border: 1px solid #1b7dec;
      }
      .step-section-point-line__point {
        margin: 0 auto;
        height: 11px;
        border-radius: 11px;
        background-color: #1b7dec;
      }
      &.step-section-point-line__up {
        margin-top: -16px;
        .step-section-point-line__line {
          border-color: #bec6c6;
        }
        .step-section-point-line__point {
          background-color: #bec6c6;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .step-section {
      .step-section__title {
        font-size: 40px;
      }
      .step-section__description {
        font-size: 24px;
      }
      .step-section__tag {
        font-size: 24px;
      }

      .step-section__content--1 {
        padding: 126px 0;
      }
      .step-section__visual-1 {
        .step-section__visual-1-text {
          font-size: 20px;
        }
      }
      .step-section__content--2 {
        padding: 90px 0;
      }
      .step-section__content--3 {
        padding: 120px 0 148px;
        .step-section__text-content {
          padding-top: 134px;
        }
      }
      .step-section-info-box {
        font-size: 16px;
        margin-left: 118px;

        &.step-section-info-box__highlight {
          & > ul {
            grid-template-columns: 260px 1fr;
          }
        }
      }
    }
  }
}
