.introduction-template {
  .map-section {
    //background: url("../../../static/images/introduction/contact@2x.png") center center no-repeat;
    padding: 100px 0 90px;

    &__title {
      margin-bottom: 32px;
      font-family: Volte, sans-serif;
      font-size: 20px;
      letter-spacing: 1px;
      font-weight: 800;
      color: #1b7ded;
    }
    .company-info {
      display: flex;
      flex-flow: column nowrap;
      margin-top: 32px;
      font-family: NotoSansCJKkr, sans-serif;
      color: #333;

      &__location {
        font-size: 18px;
        font-weight: bold;
      }
      &__contact {
        margin-top: 14px;
        font-size: 16px;
      }
    }

    img {
      max-width: 940px;
      //min-width: 1280px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .map-section {
      padding: 60px 0 90px;
      img {
        width: 100%;
        max-width: 550px;
      }
      .company-info {
        text-align: left;
        padding: 0 20px;
        line-height: 24px;

        &__location {
          font-size: 15px;
        }
        &__contact {
          font-size: 15px;
        }
      }
    }
  }
}
