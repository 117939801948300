.o2solution-template {
  .slogan-section {
    padding: 130px 20px;
    text-align: center;
    background-color: #1b7dec;
  }
  .slogan-section__slogan {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.4;
    color: #fff;
  }
  .slogan-section__slogan-sub {
    padding: 12px 0 40px 0;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.4;
    color: #a3d8ff;
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .slogan-section__slogan {
      font-size: 48px;
    }
    .slogan-section__slogan-sub {
      font-size: 32px;
    }
  }
}
