.introduction-template {
  .award-section {
    background: rgba(163, 210, 245, 0.08);
    padding: 130px 0;
    display: flex;
    flex-flow: column;
    align-items: center;

    &__title {
      font-family: Volte, sans-serif;
      font-size: 20px;
      letter-spacing: 1px;
      font-weight: 800;
      color: #1b7ded;
    }
    &__awards {
      display: flex;
      flex-flow: column nowrap;
      text-align: left;
      margin-top: 70px;
      width: 720px;

      .award {
        margin-top: 30px;
        display: flex;
        flex-flow: row nowrap;
        &:first-child { margin-top: 0; }
        &__date {
          width: 75px;
          font-family: Volte, sans-serif;
          font-size: 18px;
          font-weight: 600;
          color: #73c3ff;
        }
        &__contents {
          margin-left: 20px;
          font-family: NotoSansCJKkr, sans-serif;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: -0.3px;
          color: #333;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .award-section {
      padding-top: 110px;
      padding-bottom: 100px;

      &__awards {
        margin-top: 40px;
        text-align: center;
        width: 100%;

        .award {
          flex-flow: column nowrap;
          margin-top: 30px;

          &__date {
            width: 100%;
            font-size: 15px;
          }
          &__contents {
            font-size: 15px;
            margin-top: 6px;
            margin-left: 0;
            padding: 0 20px;
          }
        }
      }
    }
  }
}
