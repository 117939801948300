.o2solution-template {
  .inquery-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 62px;
    border-radius: 12px;
    background-color: #fff;
    color: #1b7dec;
    font-size: 18px;
    font-weight: 700;

    &:hover {
      background-color: #e8f0fe;
    }
    &:active {
      background-color: #b2d3fb;
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .inquery-button {
      height: 72px;
      border-radius: 12px;
      font-size: 20px;
    }
  }
}
