.o2solution-template {
  .banner-section {
    margin-top: 72px;
    height: 720px;
    background: url('../../../static/images/o2_solution/bg_banner@2x.png') no-repeat center center;
    background-size: cover;
    color: #fff;

    .banner-section__content {
      max-width: 1308px;
      margin: 0 auto;
      padding: 0 20px;

      & > h2 {
        margin: 0;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.3;
        padding: 175px 0 24px 0;
        padding-bottom: 24px;
      }
      & > p {
        font-size: 24px;
        font-weight: 500;
      }
      .banner-section__inquery {
        margin-top: 56px;
      }
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .banner-section {
      .banner-section__content {
        & > h2 {
          font-size: 48px;
        }
        & > p {
          font-size: 32px;
        }
      }
    }
  }
}
