.introduction-template {
  .partner-section {
    //background: url("../../../static/images/introduction/partners@2x.png") center center no-repeat;
    padding-top: 140px;
    background: #fff;

    &__title {
      margin-bottom: 32px;
      font-family: Volte, sans-serif;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 800;
      color: #1b7ded;
    }
    &__subtitle {
      margin-bottom: 32px;
      font-family: NotoSansCJKkr, sans-serif;
      font-size: 30px;
      letter-spacing: -0.47px;
      font-weight: 600;
      color: #222222;
    }
    img {
      width: 940px;
      margin-bottom: 32px;
    }
    &__partners {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      width: 940px;
      margin: 80px auto 0;

      .partner {
        width: 286px;
        //padding: 80px 24px 0 24px;
        text-align: left;
        margin-right: 40px;
        &:last-child { margin-right: 0; }
        &__name {
          display: flex;
          flex-flow: row nowrap;
          margin-bottom: 14px;
          font-size: 18px;
          color: #222222;

          p {
            font-family: NotoSansCJKkr, sans-serif;
            margin-left: 8px;
            font-size: 15px;
            line-height: 27px;
            color: #44444499;
          }
        }
        &__introduce {
          font-family: Spoqa, sans-serif;
          font-size: 15px;
          line-height: 26px;
          color: #444444;
        }
      }
    }
    .government-designated {
      background: #1b7ded;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin-top: 140px;

      .gov-logo {
        margin-top: 27px;
        margin-right: 17px;
        width: 44px;
      }
      .gov-phrase {
        margin-top: 30px;
        width: 362px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .partner-section {
      padding-top: 111px;

      &__subtitle {
        font-size: 18px;
        letter-spacing: -0.28px;
      }
      img {
        width: 320px;
        margin: 0;
      }
      &__partners {
        flex-flow: column nowrap;
        align-items: center;
        margin: 70px auto 0;
        width: auto;

        .partner {
          width: 320px;
          margin-right: 0;
          margin-top: 40px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .government-designated {
        margin-top: 110px;
        height: 80px;

        .gov-logo {
          width: 20px;
          margin-right: 5px;
          margin-top: 0;
        }
        .gov-phrase {
          width: 275px;
          margin-top: 0;
        }
      }
    }
  }
}
