.footer-section {
  background: #2c3136;
  color: #fff;
  padding: 56px 20px 96px 20px;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  .footer-section__container {
    max-width: 960px;
    margin: 0 auto;
  }
  .footer-section__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    margin-bottom: 32px;
  }
  .footer-section__socials {
    display: flex;
    align-items: center;
    margin-left: auto;
    img {
      width: 32px;
      height: 32px;
    }
  }
  .footer-section__title {
    padding-bottom: 8px;
  }
  .footer-section__info {
    color: #8c949c;
  }
  .footer-section__reg-no {
    padding-bottom: 4px;
  }
  .footer-section__address {
    display: flex;
  }
  .footer-section__address-1 {
    padding-bottom: 4px;
  }
  .footer-section__address-2 {
    padding-bottom: 16px;
    margin-left: 8px;
  }
  .footer-section__address-map {
    color: #708efc;
    font-weight: bold;
  }
  .footer-section__link-item {
    padding-bottom: 8px;
    & > span {
      color: #8c949c;
      margin-right: 8px;
    }
  }
  .footer-section__link-fax {
    color: #8c949c;
    margin-right: 8px;
  }
  .footer-section__terms {
    padding-top: 32px;
    padding-bottom: 32px;
    & > a {
      color: #fff;
    }
    & > a:first-child {
      margin-right: 32px;
    }
  }
  .footer-section__copyright {
    color: #8c949c;
  }
}

@media only screen and (max-width: 767px) {
  .footer-section__container {
    max-width: none;
    margin: 0;
  }
  .footer-section {
    .footer-section__address {
      display: block;
    }
    .footer-section__address-2 {
      margin-left: 0;
    }
  }
}
