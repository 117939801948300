.o2solution-template {
  .two-column-content {
    width: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .two-column-content__right {
      margin-top: 40px;
    }
  }
}

@media (min-width: 640px) {
  .o2solution-template {
    .two-column-content {
      width: 1074px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .two-column-content__right {
        margin-top: 0;
      }
    }
    .two-column-content--align-start {
      align-items: flex-start;
    }
    .two-column-content__item--reverse {
      order: 9999;
    }
  }
}
