.introduction-template {
  .press-section {
    background: #ffffff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    //max-width: 940px;
    //min-width: 1280px;
    padding: 130px 0;

    &__title {
      margin: 0 0 32px;
      font-family: Volte, sans-serif;
      font-size: 20px;
      font-weight: 600;
      color: #1b7ded;
    }
    &__lists {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      width: 85%;
      max-width: 940px;

      .lists__news {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        border-top: 1px solid #dcdcdc;
        padding: 32px 0;
        text-align: left;
        font-weight: bold;

        &:last-child {
          border-bottom: 1px solid #dcdcdc;
        }
        div {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          margin-bottom: 8px;
          font-size: 18px;
          font-family: NotoSansCJKkr, sans-serif;
          color: #dcdcdc;

          .news-company {
            margin-right: 8px;
            color: #1b7ded;
          }
          .news-date {
            margin-left: 8px;
            color: #888888;
          }
        }
        .news__title {
          font-size: 24px;
          font-weight: normal;
          color: #444444;

          &:hover {
            color: #007ac3;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .press-section {
      padding: 110px 0 50px;
      &__lists {
        .lists__news {
          padding: 15px 0;

          div {
            font-size: 16px;
          }
          .news__title {
            font-size: 18px;
          }
        }
      }
    }
  }
}
