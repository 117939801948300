.introduction-template {
  .service-section {
    background: rgba(163, 210, 245, 0.08);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    //min-width: 1280px;
    padding: 120px 0;
    text-align: center;

    .service-section__title {
      margin-bottom: 70px;
      font-family: Volte, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #1b7ded;
    }
    .service-list {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      width: 100%;
      max-width: 1230px;

      &__menu {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 460px;
        //height: 287px;
        padding: 0 32px;
        flex-grow: 1;

        .menu__title {
          //width: 194px;
          height: 40px;
          margin-bottom: 50px;
        }
        .menu__explanation {
          margin-bottom: 50px;
          font-family: spoqaHanSans, sans-serif;
          font-size: 15px;
          line-height: 1.87;
          letter-spacing: -0.3px;
          color: #444444;
        }
        .menu__link--button {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          width: 165px;
          height: 40px;
          padding: 0 15px;
          border: 1px solid #1b7ded;
          line-height: 40px;
          color: #1b7ded;
          img {
            width: 47px;
            height: 10px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .introduction-template {
    .service-section {
      padding: 100px 0 80px;
      .service-section__title {
        margin-bottom: 0;
      }
      .service-list {
        flex-flow: column nowrap;

        &__menu {
          width: auto;
          height: auto;
          padding: 20px 20px;
          margin-top: 30px;

          img {
            height: 32px;
          }
          .menu__title {
            //margin-top: 15px;
            height: 30px;
            margin-bottom: 30px;
          }
          .menu__explanation {
            margin-bottom: 30px;
            letter-spacing: -0.6px;
          }
          .menu__link--button {
            img {
              width: 30px;
              height: 8px;
            }
          }
        }
      }
    }
  }
}
