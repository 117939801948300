.o2solution-template {
  .o2-header-section {
    box-sizing: border-box;
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: -72px;
    padding-top: 72px;
    height: 144px;
    background-color: #fff;
    border-bottom: 1px solid #f4f7fa;

    .o2-header-section__content {
      height: 100%;
      margin: 0 auto;
      padding: 0 20px;
      display: flex;
      align-items: center;
      max-width: 1308px;
    }

    .o2-header-section__inquery {
      color: inherit;
      margin-left: auto;
      font-weight: 500;
    }
  }
}
